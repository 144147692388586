import { useMemo } from 'react';
import { AccommodationExpenseEnum, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import { useGetFinancialSummaryQuery, useGetUserProfileQuery, useGetVariablesQuery } from '@harmoney/redux';

import { CommonProps } from '../../common-props';
import { HouseholdV2 } from '../HouseholdV2/HouseholdV2';

export const UpdateHousehold = ({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) => {
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: userProfile } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const { data: userFinancialSummary } = useGetFinancialSummaryQuery(
    { id: variables?.financialProfileId?.toString(), loanApplicationId: variables?.loanApplicationId?.toString() },
    {
      skip: !variables?.loanApplicationId?.toString() || !variables?.financialProfileId?.toString(),
      refetchOnMountOrArgChange: true,
    }
  );

  const prefilledData = useMemo(() => {
    const residentialStatus = userProfile?.addresses?.find((address) => address.isCurrent)?.residentialStatus;
    const accommodationExpense = userFinancialSummary?.livingSituation?.expenses?.find(
      (expense) => expense.networthSourceName === residentialStatus
    );
    const hasDependants = userProfile?.numberOfDependants > 0;

    const childSupportExpense = userFinancialSummary?.livingSituation?.expenses?.find(
      (expense) => expense.networthSourceId === NetworthSourceEnum.EXPENSE_CHILD_SUPPORT_ID
    );
    const hasChildSupportPaid = childSupportExpense?.declaredAmount ? true : false;
    const childSupportIncome = userFinancialSummary?.livingSituation?.incomes?.find((income) =>
      income.networthSourceName.includes('receive')
    );
    const getAccommodationExpenseSituation = () => {
      if (accommodationExpense?.declaredAmount) return AccommodationExpenseEnum.SHARED;
      else if (accommodationExpense?.declaredAmount === null && accommodationExpense?.declaredTotalAmount === null)
        return AccommodationExpenseEnum.NO_COVERAGE;
      else return AccommodationExpenseEnum.FULL_PAID;
    };

    return {
      livingType: {
        primary: residentialStatus,
        secondary: '',
      },
      dependants: {
        hasDependants: hasDependants ? 'Yes' : 'No',
        numberOfDependants: userProfile?.numberOfDependants,
      },
      childSupport: {
        id: childSupportExpense?.id,
        childSupportIncomeId: childSupportIncome?.id,
        hasChildSupportReceived: childSupportIncome ? 'Yes' : 'No',
        childSupportReceivedAmount: childSupportIncome?.declaredAmount,
        childSupportReceivedFrequency: childSupportIncome?.frequency,
        hasChildSupportPaid: hasChildSupportPaid ? 'Yes' : 'No',
        childSupportPaidAmount: childSupportExpense?.declaredAmount,
        childSupportPaidFrequency: childSupportExpense?.frequency,
        hasChildSupportDeducted: childSupportExpense?.deductedFromIncome ? 'Yes' : 'No',
      },
      accommodationExpense: {
        id: accommodationExpense?.id,
        situation: getAccommodationExpenseSituation(),
        declaredAmount: accommodationExpense?.declaredAmount,
        declaredFrequency: accommodationExpense?.frequency,
        declaredTotalAmount: accommodationExpense?.declaredTotalAmount,
        declaredTotalFrequency: accommodationExpense?.frequencyForTotalAmount,
      },
    };
  }, [userFinancialSummary, userProfile]);

  const handleCancel = async () => {
    await completeTaskWithData({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      {prefilledData && (
        <HouseholdV2
          taskId={taskId}
          prefilledData={prefilledData}
          onCancel={handleCancel}
          completeTaskWithData={completeTaskWithData}
        />
      )}
    </>
  );
};
