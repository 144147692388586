import { Button, ChevronRightIcon } from '@harmoney/ui-design-system';

import { EmailYourQuoteCard, GetQuickAnswersCard, RatingsCard, WhatHappensNextCard } from './components/QuoteHelpCards';

export function QuoteHelp() {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="absolute -top-8 w-full md:w-[756px] h-[358px] md:h-[391px] rounded-b-pill md:rounded-b-full bg-gradient-to-b from-primary-darker-1 to-primary" />
      <div className="relative z-10 mb-4 md:w-[512px]">
        <ChevronRightIcon size="tiny" className="rotate-180 cursor-pointer mb-4" variant="white" />
        <p className="text-xl text-white font-bold">Not quite ready to move forward?</p>
        <span className="text-white">
          We understand that applying for a loan is a big step. These resources can help you make a more informed
          decision.
        </span>
      </div>
      <div className="relative z-10">
        <EmailYourQuoteCard />
        <GetQuickAnswersCard />
        <WhatHappensNextCard />
        <RatingsCard />
      </div>
      <Button variant="secondary" hasShadow>
        Back to Quote
      </Button>
    </div>
  );
}
