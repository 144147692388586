import { useState } from 'react';
import { QuoteOption } from '@harmoney/api-interfaces';
import { Button, Dialog, Divider, Spinner } from '@harmoney/ui-design-system';
import { convertMonthsToYears } from '@harmoney/ui-utils';
import { formatCurrency, formatFrequency } from '@harmoney/utilities';

interface QuoteSummaryV2Props {
  primaryPurpose: string;
  repaymentDetails: QuoteOption;
}

export function QuoteSummaryV2({ primaryPurpose, repaymentDetails }: QuoteSummaryV2Props) {
  const [isEstablishmentFeeOpen, setIsEstablishmentFeeOpenOpen] = useState(false);

  if (!repaymentDetails) return <Spinner size="small" />;

  const { establishmentFee, loanAmount, repaymentAmount, repaymentFrequency, termInMonths } = repaymentDetails;

  return (
    <>
      <div className="text-center mb-6">
        <p className="mb-0">You&rsquo;ll pay</p>
        <h2 className="mb-0">
          {formatCurrency(repaymentAmount)} / {formatFrequency(repaymentFrequency)}
        </h2>
        <p className="mb-0 font-bold">on a {convertMonthsToYears(termInMonths)} year term</p>
      </div>

      <ul className="text-sm">
        <li className="mb-2 flex justify-between">
          <span>{primaryPurpose}</span>
          <span className="font-medium">{formatCurrency(loanAmount)}</span>
        </li>
        <li className="flex justify-between">
          <span>One-off establishment fee</span>
          <span className="font-medium">{formatCurrency(establishmentFee)}</span>
        </li>
        <li className="mb-2 flex justify-between">
          <Button
            variant="link"
            onClick={() => setIsEstablishmentFeeOpenOpen(true)}
            className="!my-0 min-h-fit text-sm text-secondary"
          >
            What is this fee?
          </Button>
        </li>
        <Divider className="text-grey-2" />
        <li className="flex justify-between pt-2 font-medium">
          <span>Loan total</span>
          <span>{formatCurrency(loanAmount + establishmentFee)}</span>
        </li>
      </ul>

      <Dialog
        title="What is an establishment fee?"
        open={isEstablishmentFeeOpen}
        onOpenChange={() => setIsEstablishmentFeeOpenOpen(!isEstablishmentFeeOpen)}
        modal
      >
        <div className="p-4 flex flex-col">
          <p>We have a one-off establishment fee that is added to your loan balance.</p>
          <p>
            We&rsquo;re committed to transparency so apart from the one-off establishment fee, there are absolutely{' '}
            <strong>no other fees.</strong>
          </p>

          <Button
            variant="outline-secondary"
            onClick={() => setIsEstablishmentFeeOpenOpen(false)}
            className="my-4 self-center"
          >
            Got it
          </Button>
        </div>
      </Dialog>
    </>
  );
}
