import { LoanProductPurposeDto } from '@harmoney/api-interfaces';
import { LoanProductName } from '@harmoney/utilities';
import { kebabCase } from 'lodash';

export const getLoanPurposeName = (loanPurposesData: LoanProductPurposeDto[], loanPurposeId: string) => {
  if (!loanPurposesData) {
    return {
      displayName: '',
      name: '',
    };
  }
  const declaredSecondaryLoanPurpose = loanPurposesData.find((loanPurpose) => loanPurpose.id === loanPurposeId);
  return {
    displayName: declaredSecondaryLoanPurpose?.displayName,
    name: declaredSecondaryLoanPurpose?.name,
  };
};

export const calculateEstablishmentFee = (
  totalAccepted: number,
  quoteData: {
    establishmentFeeThreshold: number;
    establishmentFeeUnderThreshold: number;
    establishmentFeeOverThreshold: number;
  }
) => {
  if (!quoteData) return 0;
  return totalAccepted < quoteData.establishmentFeeThreshold
    ? quoteData.establishmentFeeUnderThreshold
    : quoteData.establishmentFeeOverThreshold;
};

export const formatLoanPurposes = (loanPurposes: LoanProductPurposeDto[], excludeId: string) => {
  return loanPurposes
    .filter(
      (purpose) =>
        purpose.name.toLowerCase() !== LoanProductName.DEBT_CONSOLIDATION.toLowerCase() && purpose.id !== excludeId
    )
    .map(({ id, displayName, name }) => ({
      label: displayName,
      value: id,
      iconName: kebabCase(name),
    }));
};

export const formatLoanPurposesForUnutilisedLimit = (loanPurposes: LoanProductPurposeDto[]) => {
  const filteredPurposes = loanPurposes.filter(
    (purpose) => purpose.name.toLowerCase() !== LoanProductName.DEBT_CONSOLIDATION.toLowerCase()
  );

  const formattedPurposes = filteredPurposes.map(({ id, displayName, name }) => ({
    label: displayName,
    value: id,
    iconName: kebabCase(name),
  }));

  return [{ label: 'Select a loan purpose', value: '' }, ...formattedPurposes];
};

export const convertMonthsToYears = (termInMonths: number) => {
  return termInMonths / 12;
};
