import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import {
  ChangeFlagInformationDto,
  LoanVariationFlag,
  loanVariationFlagMapper,
  opportunityMapper,
  SpokeDto,
  SpokeName,
  VaultAccountStatus,
  vaultAccountStatusMapper,
} from '@harmoney/api-interfaces';
import { AdminTransactionHistory, ChangeSummary } from '@harmoney/components';
import { useJourney, useTaskComponent } from '@harmoney/hooks';
import {
  useAppDispatch,
  useAppSelector,
  useCompleteTaskMutation,
  useGetAllSpokesQuery,
  useGetAllVariationsByLoanApplicationIdQuery,
  useGetLoanApplicationByIdQuery,
  useGetLoanViewDetailsByApplicationIdQuery,
  useGetRepaymentScheduleDataQuery,
  useGetTaskQuery,
} from '@harmoney/redux';
import { Alert, Badge, CommonOptionProps, Spinner } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { dayjsUTCToSydney, formatCurrency, formatFrequency } from '@harmoney/utilities';
import { LoanVariation } from '@prisma/client';
import dayjs from 'dayjs';
import { capitalize, kebabCase } from 'lodash';

import {
  setIsDirectDebitSuspendOpen,
  setIsLoanVariationApplied,
  setIsScheduleExtraPaymentOpen,
  setShowChangeSummary,
  setShowUpdateDirectDebitPlan,
  setShowVariationHistory,
} from '../../../../redux/slice/admin';
import { ActionCenterGroup, ActionControl } from '../ActionControl/ActionControl';
import { ChangeSummaryData } from '../PaymentPlan/ChangeSummaryData';
import {
  ChangeSummaryTypeEnum,
  getFinalPaymentPlanData,
  PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR,
} from '../PaymentPlan/util';

import { LoanInformationDetails } from './LoanInformationDetails';
import { LoanVariationHistory } from './LoanVariationHistory';
import { PaymentInformationDetails } from './PaymentInformationDetails';
import {
  createScheduleExtraPaymentFlags,
  createUpdatedDirectDebitFlags,
  getFlagLinkText,
  loanStatusBadgeVariant,
  smoothScrollTo,
} from './utils';
import { VariationData } from './VariationData';

export const LoanView = ({ userId = null, applicationId = null, changeSubMenuName = null }) => {
  const router = useRouter();
  userId = userId || router.query.userId;
  applicationId = applicationId || router.query.applicationId;
  const taskId = router?.query?.taskId as string;
  const done = router?.query?.done as string;
  const token = useAppSelector((state) => state?.accessToken?.value);
  const actionCenter = useAppSelector((state) => state?.admin.actionCenter);
  const isLoanVariationApplied = useAppSelector((state) => state?.admin.actionCenter.isLoanVariationApplied);
  const isDirectDebitSuspendOpen = useAppSelector((state) => state?.admin.actionCenter.isDirectDebitSuspendOpen);
  const isScheduleExtraPaymentOpen = useAppSelector((state) => state?.admin.actionCenter.isScheduleExtraPaymentOpen);
  const showVariationHistory = useAppSelector((state) => state?.admin.actionCenter.showVariationHistory);
  const showChangeSummary = useAppSelector((state) => state?.admin.actionCenter.showChangeSummary);
  const showUpdateDirectDebitPlan = useAppSelector((state) => state?.admin.actionCenter.showUpdateDirectDebitPlan);
  const dispatch = useAppDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [variationData, setVariationData] = useState<LoanVariation>(null);
  const [summaryData, setSummaryData] = useState(null);
  const [fullName, setFullName] = useState<string>('');
  const [committedBy, setCommittedBy] = useState<string>('');
  const [deletedBy, setDeletedBy] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [spokeData, setSpokeData] = useState<SpokeDto>(null);

  const getAlertMessageBySpokeName = (spokeName) => {
    switch (spokeName) {
      case SpokeName.LOAN_VARIATION:
        setAlertMessage('Variation Applied!');
        break;
      case SpokeName.DIRECT_DEBIT_SUSPENSION:
        setAlertMessage('Direct Debit Suspension applied!');
        break;
      case SpokeName.SCHEDULE_EXTRA_PAYMENT:
        setAlertMessage('Extra Payment(s) Scheduled!');
        break;
      case SpokeName.UPDATE_DIRECT_DEBIT_PLAN:
        setAlertMessage('Direct Debit Plan is updated!');
        break;
    }
  };

  const { data: loanApplicationData } = useGetLoanApplicationByIdQuery(applicationId as string, {
    skip: !token || !applicationId,
  });
  useEffect(() => {
    document.title = `Loan - ${loanApplicationData?.businessKey}`;
  }, [loanApplicationData?.businessKey]);

  const { data: loanData, isLoading: isLoanDataLoading } = useGetLoanViewDetailsByApplicationIdQuery(
    applicationId as string,
    {
      skip: !token || !applicationId,
    }
  );
  const { data: allSpokesData } = useGetAllSpokesQuery(null, {
    skip: !token,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const { data: historyData } = useGetAllVariationsByLoanApplicationIdQuery(applicationId as string, {
    skip: !token || !applicationId,
    refetchOnMountOrArgChange: true,
  });

  const { data: changeSummaryData } = useGetRepaymentScheduleDataQuery(applicationId as string, {
    skip: !token || !applicationId,
    refetchOnMountOrArgChange: true,
  });

  const finalSummaryData = changeSummaryData ? getFinalPaymentPlanData(changeSummaryData) : null;

  const useFlags = (loanData, finalSummaryData) => {
    return useMemo(() => {
      const initialFlags = loanData?.flags ?? [];
      const oneOffPayments =
        finalSummaryData?.filter((data) => data.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT) ?? [];
      const directDebitPlans =
        finalSummaryData?.filter((data) => data.type === ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN) ?? [];
      const extraPaymentFlags = createScheduleExtraPaymentFlags(oneOffPayments);
      const directDebitPlanFlags = createUpdatedDirectDebitFlags(directDebitPlans);

      return initialFlags.concat(extraPaymentFlags).concat(directDebitPlanFlags);
    }, [loanData, finalSummaryData]);
  };

  let allFlags: ChangeFlagInformationDto[] = useFlags(loanData, finalSummaryData);

  if (allFlags?.length) {
    allFlags = allFlags.filter(
      (flag) => flag.flagName !== LoanVariationFlag.SCHEDULE_EXTRA_PAYMENT_FLAG || flag?.amount
    );
  }

  const isPermanentDirectDebitSuspension = (flagName: string, expiryDate: Date) => {
    return (
      flagName === LoanVariationFlag.DIRECT_DEBIT_SUSPEND_FLAG &&
      dayjs(expiryDate).year() === PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR
    );
  };

  const getFlagTitle = (flagName: string, flagData: ChangeFlagInformationDto) => {
    if (isPermanentDirectDebitSuspension(flagName, flagData?.expiryDate)) return 'Paused permanently from ';
    switch (flagName) {
      case LoanVariationFlag.INTEREST_ACCRUED_PAUSE_FLAG:
        return 'Interest accrual paused from ';
      case LoanVariationFlag.REPAYMENT_HOLIDAY_FLAG:
        return 'Payments paused from ';
      case LoanVariationFlag.DIRECT_DEBIT_SUSPEND_FLAG:
        return 'Paused from ';
    }
  };

  const getFlagDescription = (flagName: string, flagData: ChangeFlagInformationDto) => {
    if (isPermanentDirectDebitSuspension(flagName, flagData?.expiryDate))
      return <span className="font-medium">{dayjsUTCToSydney(flagData.effectiveDate).format(DATE_FORMAT)} </span>;

    switch (flagName) {
      case LoanVariationFlag.SCHEDULE_EXTRA_PAYMENT_FLAG:
        return (
          <span>
            {formatCurrency(flagData?.amount)} scheduled for{' '}
            {dayjsUTCToSydney(flagData?.scheduledAt).format(DATE_FORMAT)}{' '}
            {flagData.numberOfOtherPayments > 0 && ` +${flagData.numberOfOtherPayments} other scheduled extra payments`}{' '}
          </span>
        );
      case LoanVariationFlag.UPDATE_DIRECT_DEBIT_FLAG:
        return (
          <span>
            <span>Next payment: {dayjs(flagData?.paymentDate).format(DATE_FORMAT)}</span>
            {flagData?.paymentFrequency && (
              <span> | Payment frequency: {capitalize(formatFrequency(flagData?.paymentFrequency))} </span>
            )}
          </span>
        );
      default:
        return (
          <span className="font-medium">
            {dayjsUTCToSydney(flagData.effectiveDate).format(DATE_FORMAT)} to{' '}
            {dayjsUTCToSydney(flagData.expiryDate).format(DATE_FORMAT)}{' '}
          </span>
        );
    }
  };

  useEffect(() => {
    if (done === 'true') {
      dispatch(setIsLoanVariationApplied(false));
      setShowAlert(true);
      delete router.query.done;
      delete router.query.taskId;
      router.replace({ query: router.query }, undefined, { shallow: true });
      setTimeout(() => {
        router.reload();
      }, 2000);
    }
  }, [done]);

  const [completeTask] = useCompleteTaskMutation();

  const { startJourney } = useJourney(
    spokeData?.processId as string,
    spokeData?.name as string,
    applicationId as string
  );

  useEffect(() => {
    if (changeSubMenuName && loanApplicationData) {
      changeSubMenuName(loanApplicationData.businessKey);
    }
  }, [changeSubMenuName, loanApplicationData]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (!url.includes('taskId')) {
        dispatch(setIsLoanVariationApplied(false));
        dispatch(setIsDirectDebitSuspendOpen(false));
        dispatch(setIsScheduleExtraPaymentOpen(false));
        dispatch(setShowVariationHistory(false));
        dispatch(setShowChangeSummary(false));
        dispatch(setShowUpdateDirectDebitPlan(false));
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const { data: taskData } = useGetTaskQuery(taskId, { refetchOnMountOrArgChange: true, skip: !token || !taskId });

  const TaskComponent = useTaskComponent(taskData);

  useEffect(() => {
    if (Object.values(actionCenter).some((value) => value === true)) {
      document.body.classList.add('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [actionCenter]);

  useEffect(() => {
    if (spokeData) {
      getAlertMessageBySpokeName(spokeData.name);
      startJourney();
    }
  }, [spokeData, startJourney]);

  const variationDisableLoanStatus = [
    vaultAccountStatusMapper[VaultAccountStatus.ACCOUNT_STATUS_PENDING],
    vaultAccountStatusMapper[VaultAccountStatus.ACCOUNT_STATUS_CLOSED],
    vaultAccountStatusMapper[VaultAccountStatus.ACCOUNT_STATUS_CANCELLED],
    vaultAccountStatusMapper[VaultAccountStatus.ACCOUNT_STATUS_PENDING_CLOSURE],
  ];

  const isDisabledLoanStatus = variationDisableLoanStatus.includes(
    vaultAccountStatusMapper[loanData?.loanInformation.loanStatus]
  );

  const suspensionOnFlags = [
    loanVariationFlagMapper[LoanVariationFlag.REPAYMENT_HOLIDAY_FLAG],
    loanVariationFlagMapper[LoanVariationFlag.DIRECT_DEBIT_SUSPEND_FLAG],
  ];

  const isRepaymentOrDirectDebitSuspended = loanData?.flags.some((flag) =>
    suspensionOnFlags.includes(loanVariationFlagMapper[flag.flagName])
  );

  const getAccessPermission = (spokeName: SpokeName) => {
    return !allSpokesData?.some((spoke) => spoke.name === spokeName);
  };

  const getSpokeData = (spokeName: SpokeName) => {
    return allSpokesData?.filter((spoke) => spoke.name === spokeName)[0];
  };
  const actionCenterOptions: ActionCenterGroup[] = useMemo(() => {
    const commonActionCenterOptions: CommonOptionProps[] = [
      {
        label: 'Loan variation',
        value: 'loan_variation',
        onClick: () => {
          setSpokeData(getSpokeData(SpokeName.LOAN_VARIATION));
          dispatch(setIsLoanVariationApplied(true));
        },
        disabled: isDisabledLoanStatus || getAccessPermission(SpokeName.LOAN_VARIATION),
      },
      {
        label: 'Suspend direct debit',
        value: 'suspend_direct_debit',
        onClick: () => {
          setSpokeData(getSpokeData(SpokeName.DIRECT_DEBIT_SUSPENSION));
          dispatch(setIsDirectDebitSuspendOpen(true));
        },
        disabled: isDisabledLoanStatus || getAccessPermission(SpokeName.DIRECT_DEBIT_SUSPENSION),
      },
      {
        label: 'Schedule extra payment',
        value: 'schedule_extra_payment',
        onClick: () => {
          setSpokeData(getSpokeData(SpokeName.SCHEDULE_EXTRA_PAYMENT));
          dispatch(setIsScheduleExtraPaymentOpen(true));
        },
        disabled: isDisabledLoanStatus || getAccessPermission(SpokeName.SCHEDULE_EXTRA_PAYMENT),
      },
      {
        label: 'Update direct debit plan',
        value: 'update_direct_debit_plan',
        onClick: () => {
          setSpokeData(getSpokeData(SpokeName.UPDATE_DIRECT_DEBIT_PLAN));
          dispatch(setShowUpdateDirectDebitPlan(true));
        },
        disabled:
          isDisabledLoanStatus ||
          getAccessPermission(SpokeName.UPDATE_DIRECT_DEBIT_PLAN) ||
          isRepaymentOrDirectDebitSuspended,
      },
    ];

    return [
      {
        name: 'Actions',
        options: commonActionCenterOptions,
      },
    ];
  }, [allSpokesData, loanData?.loanInformation.loanStatus]);

  const handleVariationHistoryData = (data, fullName) => {
    dispatch(setShowVariationHistory(true));
    setFullName(fullName);
    setVariationData(data);
  };

  const handleChangeSummaryData = (data, committedBy, deletedBy) => {
    dispatch(setShowChangeSummary(true));
    setCommittedBy(committedBy);
    setDeletedBy(deletedBy);
    setSummaryData(data);
  };

  const redirectToMakeExtraPayment = () => {
    setSpokeData(getSpokeData(SpokeName.SCHEDULE_EXTRA_PAYMENT));
    dispatch(setIsScheduleExtraPaymentOpen(true));
    dispatch(setShowUpdateDirectDebitPlan(false));
  };

  const handleSuccessfulRemoval = (type: string) => {
    switch (type) {
      case ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT:
        setAlertMessage('The direct debit suspension has been removed.');
        break;
      case ChangeSummaryTypeEnum.ONE_OFF_PAYMENT:
        setAlertMessage('Scheduled extra payment(s) has been deleted.');
        break;
    }
    dispatch(setShowChangeSummary(false));
    setShowAlert(true);
    setTimeout(() => {
      router.reload();
    }, 2000);
  };

  if (isLoanDataLoading || !token) {
    return (
      <div className="align-center mt-32 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={`grid grid-cols-7 grid-rows-1 gap-4 transition-all ${
        Object.values(actionCenter).some((value) => value === true)
          ? 'mr-2 h-[calc(100vh-75px)] overflow-hidden'
          : 'mr-8 overflow-auto sm:mr-12 md:mx-32'
      }`}
    >
      <div
        className={`break-words p-4 ${
          Object.values(actionCenter).some((value) => value === true)
            ? 'col-span-4 overflow-y-scroll'
            : 'col-span-7 overflow-clip'
        }`}
      >
        <div className="mb-4 flex items-center justify-between">
          <div className="flex flex-col">
            <h2 className="text-primary mb-0">
              {loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName}
            </h2>
            <div className="flex items-center pt-3 ">
              <p className="text-base text-gray-500 mr-3 mt-3">
                {opportunityMapper[loanApplicationData?.originationType]}
              </p>
              <Badge
                className="flex-shrink-0 h-auto"
                variant={loanStatusBadgeVariant[loanData?.loanInformation?.loanStatus]}
                label={vaultAccountStatusMapper[loanData?.loanInformation?.loanStatus]}
              />
            </div>
          </div>

          <div className="flex flex-col items-end gap-y-1">
            <span>{`Loan number: ${loanApplicationData?.businessKey}`}</span>
            <span>
              Application Id:{' '}
              <Link
                title={`View Application ${loanApplicationData?.id}`}
                href={`application?applicationId=${loanApplicationData?.id}&userId=${userId}`}
                passHref
              >
                {loanApplicationData?.id}
              </Link>
            </span>
            <span>{`Vault Id: ${loanData?.vaultId}`}</span>
          </div>
        </div>
        {showAlert && <Alert variant="success" className="mb-4" title={alertMessage} />}
        {allFlags.length > 0 && (
          <Alert
            variant="info"
            title={`There ${allFlags.length > 1 ? 'are' : 'is'} ${allFlags.length} active ${allFlags.length > 1 ? 'updates' : 'update'} to this loan. Please review before making any additional changes.`}
          >
            {allFlags?.map((flag, index) => {
              return (
                <>
                  <span className="mb-2 block" key={index}>
                    <span className="font-medium">{loanVariationFlagMapper[flag.flagName]}: </span>
                    <span>
                      {getFlagTitle(flag.flagName, flag)}
                      {getFlagDescription(flag.flagName, flag)}
                    </span>
                    <Link
                      href={`#${kebabCase(getFlagLinkText(flag.flagName as LoanVariationFlag))}`}
                      onClick={(e) => {
                        e.preventDefault();
                        smoothScrollTo(kebabCase(getFlagLinkText(flag.flagName as LoanVariationFlag)));
                      }}
                    >
                      View in {getFlagLinkText(flag.flagName as LoanVariationFlag)}
                    </Link>
                  </span>
                </>
              );
            })}
          </Alert>
        )}
        <ActionControl actionsGroup={actionCenterOptions} />
        <LoanInformationDetails
          loanInformationData={loanData?.loanInformation}
          originationType={loanApplicationData?.originationType}
          loanProduct={loanApplicationData?.loanProduct.name}
        />
        <PaymentInformationDetails paymentInformationData={loanData?.paymentInformation} />
        <AdminTransactionHistory
          loanNumber={loanApplicationData?.businessKey}
          loanApplicationId={loanApplicationData?.id}
        />
        <ChangeSummary changeSummaryData={changeSummaryData} sendData={handleChangeSummaryData} />
        <LoanVariationHistory historyData={historyData} sendData={handleVariationHistoryData} />
      </div>
      {Object.values(actionCenter).some((value) => value === true) && (
        <div className="animate-slide-right col-span-3 col-start-5 overflow-y-scroll transition-all pb-32">
          {isLoanVariationApplied &&
            (TaskComponent ? (
              <TaskComponent
                taskId={taskId}
                loanApplicationId={applicationId as string}
                loanInformation={loanData?.loanInformation}
                paymentInformation={loanData?.paymentInformation}
                completeTask={completeTask}
              />
            ) : (
              <Spinner />
            ))}
          {showVariationHistory &&
            (variationData ? (
              <VariationData variationData={variationData} historyView fullName={fullName} />
            ) : (
              <Spinner />
            ))}
          {showChangeSummary &&
            (summaryData ? (
              <ChangeSummaryData
                summaryData={summaryData}
                committedBy={committedBy}
                deletedBy={deletedBy}
                loanApplicationId={applicationId as string}
                onSuccessfulRemoval={handleSuccessfulRemoval}
                cancelAccessPermission={getAccessPermission}
              />
            ) : (
              <Spinner />
            ))}
          {isDirectDebitSuspendOpen &&
            (TaskComponent ? (
              <TaskComponent
                taskId={taskId}
                loanInformation={loanData?.loanInformation}
                loanApplicationId={applicationId as string}
                completeTask={completeTask}
              />
            ) : (
              <Spinner />
            ))}
          {isScheduleExtraPaymentOpen &&
            (TaskComponent ? (
              <TaskComponent
                taskId={taskId}
                loanInformation={loanData?.loanInformation}
                paymentInformation={loanData?.paymentInformation}
                loanApplicationId={applicationId as string}
                completeTask={completeTask}
              />
            ) : (
              <Spinner />
            ))}
          {showUpdateDirectDebitPlan &&
            (TaskComponent ? (
              <TaskComponent
                taskId={taskId}
                loanApplicationId={applicationId as string}
                spokeId={spokeData?.id}
                paymentInfo={loanData?.paymentInformation}
                userId={userId}
                completeTask={completeTask}
                redirectToMakeExtraPayment={redirectToMakeExtraPayment}
              />
            ) : (
              <Spinner />
            ))}
        </div>
      )}
    </div>
  );
};
