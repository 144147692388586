import { useMemo, useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useGetPostApprovalConsolidatedDebtsQuery,
  useGetVariablesQuery,
  useSaveDisbursementsMutation,
} from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Form, useForm } from '@harmoney/ui-design-system';
import { errors } from '@harmoney/ui-utils';
import { isHarmoneyDebt, isNonHarmoneyDebt, LiabilityProvider } from '@harmoney/utilities';
import { PaymentTypeEnum } from '@prisma/client';
import { Prisma } from '@prisma/client';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

import { DebtPaymentDetail } from './DebtPaymentDetail/DebtPaymentDetail';
import { HarmoneyPaymentDetail } from './HarmoneyPaymentDetail/HarmoneyPaymentDetail';

const bsbSchema = z.object({
  bsbNumber: z.string(),
  bsbAccountNumber: z.string().regex(/^\d{4,9}$/, { message: 'Please enter 4-9 digit number' }),
  bsbReference: z.string().min(1, { message: errors.requiredField('Reference') }),
});
const bpaySchema = z.object({
  bpayBillerCode: z.string().regex(/^\d{4,6}$/, { message: 'Please enter a 4-6 digit number' }),
  bpayReference: z.string().regex(/^\d{2,20}$/, { message: 'Please enter a 2-20 digit number' }),
});

const baseFormSchema = z.array(
  z
    .discriminatedUnion('billerType', [
      z
        .object({
          billerType: z.literal(PaymentTypeEnum.BSB),
        })
        .merge(bsbSchema),
      z
        .object({
          billerType: z.literal(PaymentTypeEnum.BPAY),
        })
        .merge(bpaySchema),
    ])
    .refine(
      (val) => {
        const bpayBillerCode = val['bpayBillerCode'];
        if (val.billerType === PaymentTypeEnum.BPAY && (bpayBillerCode?.length < 4 || bpayBillerCode?.length > 6))
          return false;
        return true;
      },
      {
        message: 'Please enter 4-6 digit number',
        path: ['bpayBillerCode'],
      }
    )
    .refine(
      (val) => {
        const bpayReference = val['bpayReference'];
        if (val.billerType === PaymentTypeEnum.BPAY && (bpayReference?.length < 2 || bpayReference?.length > 20))
          return false;
        return true;
      },
      {
        message: 'Please enter 2-20 digit number',
        path: ['bpayReference'],
      }
    )
    .refine(
      (val) => {
        const bsbNumber = val['bsbNumber'];
        if (val.billerType === PaymentTypeEnum.BSB && (bsbNumber?.length < 7 || bsbNumber?.length > 7)) return false;
        return true;
      },
      {
        message: 'Please enter a valid 6 digit BSB number',
        path: ['bsbNumber'],
      }
    )
    .refine(
      (val) => {
        const bsbAccountNumber = val['bsbAccountNumber'];
        if (val.billerType === PaymentTypeEnum.BSB && (bsbAccountNumber?.length < 4 || bsbAccountNumber?.length > 9))
          return false;
        return true;
      },
      {
        message: 'Please enter a 4-9 digit number',
        path: ['bsbAccountNumber'],
      }
    )
);

const rootSchema = z.object({
  debts: baseFormSchema,
});

export function DebtPayment({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const { data: variables } = useGetVariablesQuery(taskId);
  const [saveDisbursementsResponse, setSaveDisbursementsResponse] = useState(null);

  const [saveDisbursements] = useSaveDisbursementsMutation();

  const { data: consolidatedDebts } = useGetPostApprovalConsolidatedDebtsQuery();

  const form = useForm({
    mode: 'onTouched',
    schema: rootSchema,
    defaultValues: {
      debts: [],
    },
  });
  const {
    register,
    watch,
    formState: { isSubmitting },
  } = form;

  const formData = watch();

  const handleSubmit = async () => {
    const loanApplicationId = variables.loanApplicationId.toString();
    const disbursements: Prisma.DisbursementCreateManyInput[] = [];
    formData.debts.map((d, i) => {
      const liability = consolidatedNonHarmoneyDebts[i];
      disbursements.push({
        loanApplicationId,
        liabilityId: liability.id,
        paymentType: PaymentTypeEnum.BSB === d['billerType'] ? PaymentTypeEnum.directCredit : d['billerType'],
        amount: liability.outstandingBalance,
        bpayBillerCode: d['bpayBillerCode'],
        reference: d['bpayReference'] || d['bsbReference'],
        bsb: d['bsbNumber'],
        accountNumber: d['bsbAccountNumber'],
        accountName: liability.provider,
      });
    });
    consolidatedHarmoneyDebts.forEach((debt) => {
      disbursements.push({
        loanApplicationId,
        liabilityId: debt.id,
        paymentType: PaymentTypeEnum.directCredit,
        amount: debt.outstandingBalance,
        accountName: LiabilityProvider.Harmoney,
        reference: debt.otherProvider,
      });
    });
    await saveDisbursements(disbursements)
      .unwrap()
      .then(() => completeTask({ taskId }))
      .catch((error) => setSaveDisbursementsResponse(error));
  };

  const { consolidatedHarmoneyDebts, consolidatedNonHarmoneyDebts } = useMemo(() => {
    const consolidatedHarmoneyDebts = consolidatedDebts?.filter(isHarmoneyDebt);
    const consolidatedNonHarmoneyDebts = consolidatedDebts?.filter(isNonHarmoneyDebt);
    return { consolidatedHarmoneyDebts, consolidatedNonHarmoneyDebts };
  }, [consolidatedDebts]);

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Your <span className="text-primary">debt payments</span>
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        {consolidatedNonHarmoneyDebts && consolidatedNonHarmoneyDebts.length > 0 && (
          <DebtPaymentDetail
            form={form}
            errors={saveDisbursementsResponse}
            debts={consolidatedNonHarmoneyDebts}
            formData={formData}
            register={register}
          />
        )}

        {consolidatedHarmoneyDebts && consolidatedHarmoneyDebts?.length > 0 && (
          <HarmoneyPaymentDetail debts={consolidatedHarmoneyDebts} />
        )}
        <Button
          alignIcon="end"
          isLoading={isSubmitting}
          type="submit"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </>
  );
}
