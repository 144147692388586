import { AmountInput, CommonOptionProps, Spinner, ToggleGroup, useFormContext } from '@harmoney/ui-design-system';
import { frequencyOptionsWithoutDefault } from '@harmoney/ui-utils';

import { LoanPeriodAlertV2 } from './LoanPeriodAlertV2';

interface RepaymentCalculatorProps {
  targetRef: React.RefObject<HTMLDivElement>;
  formattedQuoteOptions: CommonOptionProps[];
  isSameLoanLimitAcrossOptions: boolean;
}

export const RepaymentCalculator = ({
  targetRef,
  formattedQuoteOptions,
  isSameLoanLimitAcrossOptions = true,
}: RepaymentCalculatorProps) => {
  const { register } = useFormContext();

  return (
    <>
      <div ref={targetRef}>
        <AmountInput {...register('amount')} label="How much do you want to borrow?" maxLength={6} />
      </div>

      {!isSameLoanLimitAcrossOptions && <LoanPeriodAlertV2 className="mt-2 mb-6" />}

      <ToggleGroup
        {...register('repaymentFrequency')}
        options={frequencyOptionsWithoutDefault}
        label="How would you like to repay your loan?"
        className="my-6 sm:min-w-full"
      />

      <fieldset>
        <legend className="mb-2">Over how many years?</legend>
        {formattedQuoteOptions ? (
          <ToggleGroup {...register('termInMonths')} options={formattedQuoteOptions} className="mb-6 sm:min-w-full" />
        ) : (
          <div className="flex">
            <Spinner size="small" />
          </div>
        )}
      </fieldset>
    </>
  );
};
