import { useEffect } from 'react';
import { eventAnalytics } from '../analytics/event-analytics';
import { isDevelopment, isPreview } from '../util';

export const useEventAnalytics = () => {
  useEffect(() => {
    if (isDevelopment() || isPreview()) {
      return;
    }

    const handleEventAnalyticsInitialisation = async () => {
      await eventAnalytics.load();
    };

    handleEventAnalyticsInitialisation();
  }, []);
};
