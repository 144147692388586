import { useEffect, useMemo, useState } from 'react';
import { Frequency, RepaymentFrequency } from '@harmoney/api-interfaces';
import {
  ConsolidateDebt,
  useCalculateDebtConBenefits,
  useFormatQuoteOptions,
  useFriendlyURL,
  useQuoteData,
} from '@harmoney/hooks';
import {
  useAppSelector,
  useGetLoanApplicationQuery,
  useGetLoanPurposesForPersonalLoanQuery,
  useGetPostApprovalConsolidatedDebtsQuery,
  useGetQuoteOptionsQuery,
  useGetUserQuery,
  useGetVariablesQuery,
  useSubmitLoanApplicationMutation,
  useUpdateLoanPurposesMutation,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_APPLICATION_SUBMITTED } from '@harmoney/ui-app-shell';
import { Button, Form, Spinner, useForm } from '@harmoney/ui-design-system';
import { DebtConsolidationBenefitEnum, LoanApplicationStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isUndefined } from 'lodash';

import { CommonProps } from '../../common-props';
import { DebtConBenefitsRender } from '../../debt-consolidation/ConsolidateDebt/components';
import { MultiLoanPurposes } from '../DebtConsolidationQuoteV2/components';
import { DebtsSummaryV2 } from '../DebtConsolidationQuoteV2/components/DebtsSummaryV2';
import { ExtraLimitOption } from '../DebtConsolidationQuoteV2/ExtraLimit';
import { createFormSchema, defaultValues, validationMessages } from '../DebtConsolidationQuoteV2/form-config';
import {
  calculateEstablishmentFee,
  formatLoanPurposes,
  getLoanPurposeName,
  LoanPeriodAlert,
  QuoteDisclaimer,
  QuoteIntroduction,
  QuoteSummary,
  RepaymentOptions,
} from '../shared';

dayjs.extend(relativeTime);

export function DebtConsolidationQuoteV4({ loanProductData, taskId, completeTask, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const userId = useAppSelector((state) => state.userId.value);
  const [borrowingLimit, setBorrowingLimit] = useState<{ lowerLimit: number; upperLimit: number }>({
    lowerLimit: 0,
    upperLimit: 0,
  });
  const [uiState, setUiState] = useState({
    isSecondaryPurposeFormOpen: false,
    isSubmitting: false,
    errorMsgForSecondaryPurposeAmount: null,
  });

  const formSchema = createFormSchema(borrowingLimit.lowerLimit, borrowingLimit.upperLimit);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });
  const {
    watch,
    register,
    unregister,
    setError,
    clearErrors,
    formState: { errors },
  } = form;
  const watchFields = watch();

  const { data: userData } = useGetUserQuery();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { quoteData } = useQuoteData(taskId);
  const { data: loanPurposesData } = useGetLoanPurposesForPersonalLoanQuery(null, {
    skip: !loanProductData,
  });

  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });

  const { totalAcceptedAmount, establishmentFee, totalLoanAmount } = useMemo(() => {
    const totalAccepted = watchFields.primaryPurposeAmount + (watchFields.secondaryPurposeAmount || 0);
    const fee = calculateEstablishmentFee(totalAccepted, quoteData);
    const totalLoan = totalAccepted + fee;
    return { totalAcceptedAmount: totalAccepted, establishmentFee: fee, totalLoanAmount: totalLoan };
  }, [watchFields.primaryPurposeAmount, watchFields.secondaryPurposeAmount, quoteData]);

  const { debtConTotalAmount, debtConEstablishmentFee } = useMemo(() => {
    const debtConTotalAmount = watchFields.primaryPurposeAmount;
    const fee = calculateEstablishmentFee(debtConTotalAmount, quoteData);

    return { debtConTotalAmount, debtConEstablishmentFee: fee };
  }, [watchFields.primaryPurposeAmount, quoteData]);

  const loanApplicationPurposes = useMemo(() => {
    return loanApplicationData?.loanApplicationPurposes || [];
  }, [loanApplicationData?.loanApplicationPurposes]);

  const availableSecondaryPurposes = useMemo(() => {
    if (!loanPurposesData || !loanApplicationPurposes) return [];
    const excludeId = loanApplicationPurposes[0]?.loanPurposeId;
    return formatLoanPurposes(loanPurposesData, excludeId);
  }, [loanApplicationPurposes, loanPurposesData]);

  const maxExtraLimitForSecondaryPurpose = useMemo(() => {
    return loanApplicationData ? loanApplicationData.applicationBorrowingLimit - watchFields.primaryPurposeAmount : 0;
  }, [watchFields.primaryPurposeAmount, loanApplicationData]);

  const { data: quoteOptionsData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: totalAcceptedAmount,
      establishmentFee,
    },
    {
      skip: !variables || !totalAcceptedAmount || !establishmentFee,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: debtConQuoteOptionsData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: debtConTotalAmount,
      establishmentFee: debtConEstablishmentFee,
    },
    {
      skip: !variables || !debtConEstablishmentFee || !debtConTotalAmount,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: postApprovalDebtsData } = useGetPostApprovalConsolidatedDebtsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const formattedQuoteOptions = useFormatQuoteOptions(
    watchFields.repaymentFrequency as RepaymentFrequency,
    quoteOptionsData
  );

  const hmyRepaymentAmount = useMemo(() => {
    const result = debtConQuoteOptionsData?.find(
      (option) => option.termInMonths === 84 && option.repaymentFrequency === RepaymentFrequency.MONTHLY
    )?.repaymentAmount;
    return result || undefined;
  }, [debtConQuoteOptionsData]);

  const isSameLoanLimitAcrossOptions = useMemo(() => {
    return (
      quoteOptionsData?.every(({ loanLimitFromUmi }) => loanLimitFromUmi === quoteOptionsData[0].loanLimitFromUmi) ||
      false
    );
  }, [quoteOptionsData]);

  const [updateLoanPurposes] = useUpdateLoanPurposesMutation();
  const [submitLoanApplication] = useSubmitLoanApplicationMutation();

  const consolidatedDebt = useMemo(() => {
    return {
      interestRate: Number(loanApplicationData?.finalInterestRate),
      maximumBorrowingLimit: variables?.quote['maximumBorrowingLimit'],
      postApprovalDebtsData: postApprovalDebtsData,
      hmyRepaymentAmount: hmyRepaymentAmount,
    } as ConsolidateDebt;
  }, [loanApplicationData, variables, postApprovalDebtsData, hmyRepaymentAmount]);

  const benefits = useCalculateDebtConBenefits(consolidatedDebt);

  const [debtConsolidationBenefits, setDebtConsolidationBenefits] = useState<DebtConsolidationBenefitEnum[]>([]);

  useEffect(() => {
    if (benefits) {
      const filteredBenefits = benefits.filter((b) => b.display).map((b) => b.id);
      setDebtConsolidationBenefits(filteredBenefits);
    }
  }, [benefits]);

  useEffect(() => {
    if (!quoteData || !loanApplicationData) return;

    setBorrowingLimit({
      lowerLimit: quoteData.minimumBorrowingLimit,
      upperLimit: loanApplicationData.quotePresentedAmount,
    });

    if (loanApplicationPurposes[0].fundedAmount) {
      if (!isUndefined(watchFields.primaryPurposeAmount)) {
        form.setValue('primaryPurposeAmount', watchFields.primaryPurposeAmount);
      } else {
        form.setValue('primaryPurposeAmount', loanApplicationData?.quoteMaxBorrowingLimit as number);
      }
    } else {
      form.setValue('primaryPurposeAmount', loanApplicationData?.quoteMaxBorrowingLimit as number);
    }

    form.setValue('termInMonths', +quoteData.termInMonths);
  }, [loanApplicationPurposes, watchFields.primaryPurposeAmount, loanApplicationData, quoteData]);

  useEffect(() => {
    const { lowerLimit, upperLimit } = borrowingLimit;

    const getErrorMessage = () => {
      const totalAmount = watchFields.primaryPurposeAmount + watchFields.secondaryPurposeAmount;
      if (totalAmount < lowerLimit) {
        return validationMessages.lowerLimitError(lowerLimit);
      }
      if (totalAmount > upperLimit) {
        return validationMessages.upperLimitError(upperLimit - watchFields.primaryPurposeAmount);
      }
      if (!watchFields.secondaryPurposeAmount) {
        return validationMessages.secondaryPurposeAmountError;
      }
      return null;
    };

    const errorMessage = getErrorMessage();
    if (
      uiState.isSecondaryPurposeFormOpen &&
      errorMessage &&
      (errors.secondaryPurposeAmount || watchFields.secondaryPurposeAmount !== undefined)
    ) {
      setUiState((prevState) => ({
        ...prevState,
        errorMsgForSecondaryPurposeAmount: errorMessage,
      }));
    } else {
      setUiState((prevState) => ({
        ...prevState,
        errorMsgForSecondaryPurposeAmount: '',
      }));
    }
    if (uiState.isSecondaryPurposeFormOpen) {
      form.setValue('hasAddedAdditionalPurpose', true);
    } else {
      form.setValue('hasAddedAdditionalPurpose', false);
    }
  }, [
    form,
    borrowingLimit,
    loanApplicationPurposes,
    watchFields.primaryPurposeAmount,
    watchFields.secondaryPurposeAmount,
    watchFields.secondaryPurposeId,
    uiState.isSecondaryPurposeFormOpen,
    setError,
    clearErrors,
    errors,
  ]);

  const handleBorrowMoreClick = () => {
    setUiState((prevState) => ({
      ...prevState,
      isSecondaryPurposeFormOpen: true,
    }));
  };

  const handleDeleteClick = () => {
    unregister(['secondaryPurposeId', 'secondaryPurposeAmount']);
    setUiState((prevState) => ({
      ...prevState,
      isSecondaryPurposeFormOpen: false,
      errorMsgForSecondaryPurposeAmount: '',
    }));
  };

  const submitForm = async () => {
    await submitLoanApplication({
      id: variables.loanApplicationId.toString(),
      termInMonths: +form.getValues('termInMonths'),
      fundedAmount: +totalAcceptedAmount,
      repaymentFrequency: form.getValues('repaymentFrequency') as unknown as Frequency,
      status: LoanApplicationStatusEnum.application_in_progress,
      debtConsolidationBenefits: debtConsolidationBenefits,
    });

    const primaryLoanPurpose = {
      id: loanApplicationPurposes[0].loanPurposeId,
      requestedAmount: +watchFields.primaryPurposeAmount,
      fundedAmount: +watchFields.primaryPurposeAmount,
    };

    const secondaryLoanPurpose = {
      id: watchFields.secondaryPurposeId,
      requestedAmount: +watchFields.secondaryPurposeAmount,
      fundedAmount: +watchFields.secondaryPurposeAmount,
    };

    await updateLoanPurposes({
      applicationId: variables.loanApplicationId.toString(),
      loanPurposes: watchFields.secondaryPurposeAmount
        ? [primaryLoanPurpose, secondaryLoanPurpose]
        : [primaryLoanPurpose],
    });

    eventAnalytics.track(LOAN_APPLICATION_SUBMITTED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    completeTask({ taskId });
  };

  const handleSubmit = async () => {
    setUiState((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));

    await submitForm();
  };

  if (!quoteData) return <Spinner />;

  return (
    <>
      <QuoteIntroduction preferredName={userData?.preferredName} isDebtCon={true} />

      <DebtsSummaryV2
        loanApplicationData={loanApplicationData}
        postApprovalDebtsData={postApprovalDebtsData}
        withCard={true}
      />

      {benefits && <DebtConBenefitsRender benefits={benefits} />}

      {loanApplicationData?.isEligibleForExtraLimit &&
        maxExtraLimitForSecondaryPurpose >= loanApplicationData.minExtraLimit &&
        !uiState.isSecondaryPurposeFormOpen &&
        !errors?.primaryPurposeAmount?.message && (
          <ExtraLimitOption
            {...{ loanApplicationData, maxExtraLimitForSecondaryPurpose, onBorrowMoreClick: handleBorrowMoreClick }}
          />
        )}

      <Form form={form} onSubmit={handleSubmit}>
        {loanApplicationPurposes.length > 0 && (
          <MultiLoanPurposes
            {...{
              loanApplicationData,
              loanPurposesData,
              availableSecondaryPurposes,
              loanApplicationPurposes,
              uiState,
              primaryPurposeAmount: watchFields.primaryPurposeAmount,
              secondaryPurposeId: watchFields.secondaryPurposeId,
              secondaryPurposeAmount: watchFields.secondaryPurposeAmount,
              maxExtraLimitForSecondaryPurpose,
              register,
              onDeleteClick: handleDeleteClick,
            }}
          />
        )}

        {!isSameLoanLimitAcrossOptions && <LoanPeriodAlert className="mb-6" />}

        <RepaymentOptions formattedQuoteOptions={formattedQuoteOptions} register={register} />

        {watchFields.repaymentFrequency && (
          <QuoteSummary
            primaryPurpose={loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName}
            primaryPurposeAmount={watchFields.primaryPurposeAmount}
            secondaryPurpose={getLoanPurposeName(loanPurposesData, watchFields.secondaryPurposeId)?.displayName}
            secondaryPurposeAmount={watchFields.secondaryPurposeAmount}
            establishmentFee={establishmentFee}
            totalLoanAmount={totalLoanAmount}
            interestRate={loanApplicationData?.finalInterestRate as unknown as number}
          />
        )}

        <QuoteDisclaimer isDebtCon={true} />

        <div className="flex justify-center">
          <Button variant="primary" isLoading={uiState.isSubmitting} type="submit" hasShadow>
            Submit application
          </Button>
        </div>
      </Form>
    </>
  );
}
