export function isDevelopment(): boolean {
  return process.env['NEXT_PUBLIC_ENVIRONMENT_NAME'] === 'development';
}

export function isPreview(): boolean {
  return process.env['NEXT_PUBLIC_ENVIRONMENT_NAME'] === 'preview';
}

export function isStaging(): boolean {
  return process.env['NEXT_PUBLIC_ENVIRONMENT_NAME'] === 'staging';
}

export function isProduction(): boolean {
  return process.env['NEXT_PUBLIC_ENVIRONMENT_NAME'] === 'production';
}
