import { Alert } from '@harmoney/ui-design-system';

interface LoanPeriodProps {
  className?: string;
}

export function LoanPeriodAlertV2({ className }: LoanPeriodProps) {
  return (
    <>
      <Alert variant="info" className={className}>
        Changing your borrowing amount may change the affordable loan terms (3, 5, or 7 years) available to you.
      </Alert>
    </>
  );
}
