import { useMemo, useState } from 'react';
import { LoanApplicationDto } from '@harmoney/api-interfaces';
import { useGetPostApprovalConsolidatedDebtsQuery } from '@harmoney/redux';
import { eventAnalytics, QUOTE_READY } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, ClockLightningIcon, Divider } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

import { CommonProps } from '../../common-props';
import { DebtsSummaryV2 } from '../DebtConsolidationQuoteV2/components/DebtsSummaryV2';

import { TimeDisplayBySydneyTimeZone } from './QuoteReadyV2';

import 'swiper/css';

interface QuoteReadyDebtConProps extends CommonProps {
  loanApplicationData: LoanApplicationDto;
}

export function QuoteReadyDebtCon({
  loanApplicationData,
  loanProductData,
  taskId,
  completeTask,
}: QuoteReadyDebtConProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: postApprovalDebtsData } = useGetPostApprovalConsolidatedDebtsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const maxExtraLimitForSecondaryPurpose = useMemo(() => {
    return loanApplicationData
      ? loanApplicationData.applicationBorrowingLimit - loanApplicationData?.quoteMaxBorrowingLimit
      : 0;
  }, [loanApplicationData]);

  const handleContinueClick = async () => {
    setIsSubmitting(true);

    await completeTask({
      taskId,
    });
    eventAnalytics.track(QUOTE_READY, {
      taskid_str: taskId,
    });
  };
  return (
    <>
      <Card>
        <h1>
          Congratulations, here&rsquo;s your <span className="text-primary">quote</span>
        </h1>
        {loanProductData && loanApplicationData && (
          <>
            <p className="mb-6">
              Fixed interest rate of{' '}
              <span className="font-medium">{loanApplicationData?.finalInterestRate as unknown as number}% p.a.</span>
            </p>

            <DebtsSummaryV2 loanApplicationData={loanApplicationData} postApprovalDebtsData={postApprovalDebtsData} />

            <p>
              {loanApplicationData?.isEligibleForExtraLimit &&
                maxExtraLimitForSecondaryPurpose >= loanApplicationData.minExtraLimit && (
                  <>
                    <p className="text-sm text-grey-4 mb-0">(Optional)</p>
                    <p className="flex flex-row justify-between items-center gap-2 mb-0 text-sm">
                      Borrow extra for something else
                      <span>{formatCurrency(maxExtraLimitForSecondaryPurpose)}</span>
                    </p>
                    <Divider className="mx-0 text-grey-2 my-2" />
                    <p className="flex flex-row justify-between items-center gap-2 mb-0">
                      Total borrowing limit
                      <span className="font-medium">{formatCurrency(loanApplicationData?.quotePresentedAmount)}</span>
                    </p>
                  </>
                )}
            </p>
          </>
        )}
      </Card>
      <Card className="flex items-center !p-4">
        <span className="mr-2 flex">
          <ClockLightningIcon />
        </span>
        <span className="text-sm">
          Hit continue and your debts could be paid off <TimeDisplayBySydneyTimeZone />
        </span>
      </Card>
      <p className="text-sm mb-6">
        By continuing, I understand that Harmoney cannot guarantee a lower interest cost. If I haven&rsquo;t supplied
        precise payout amounts, refinancing might result in additional costs.
      </p>
      <div className="mb-6 text-center">
        <Button
          onClick={handleContinueClick}
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </>
  );
}
