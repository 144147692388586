import { RepeatEligibilityCheckResultDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const repeatApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getRepeatEligibility: builder.query<RepeatEligibilityCheckResultDto, string>({
      query: (baseDate) => ({
        url: '/repeat/eligibility-check',
        method: 'GET',
        params: { baseDate },
      }),
    }),
    getRepeatEligibilityByUserId: builder.query<RepeatEligibilityCheckResultDto, { baseDate: string; userId: string }>({
      query: ({ userId, baseDate }) => ({
        url: '/repeat/admin/eligibility-check',
        method: 'GET',
        params: { baseDate, userId },
      }),
      keepUnusedDataFor: 0,
      providesTags: ['RepeatEligibility'],
    }),
  }),
});

export const { useGetRepeatEligibilityQuery, useGetRepeatEligibilityByUserIdQuery } = repeatApi;
