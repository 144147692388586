import { useMemo, useState } from 'react';
import {
  useAppSelector,
  useGetAllLoanApplicationsByUserIdQuery,
  useGetAllLoansByUserIdQuery,
  useGetRepeatEligibilityByUserIdQuery,
} from '@harmoney/redux';
import { boldContent, Card, Label, Spinner } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { Icon } from '@iconify/react';
import { LoanApplicationStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';

function isReturningCustomer(activeLoanCount: number, successfulLoanCount: number) {
  return activeLoanCount === 0 && successfulLoanCount > 0;
}

export const CustomerProfileSummary = ({ userId }: { userId: string }) => {
  const token = useAppSelector((state) => state?.accessToken?.value);
  const [displayedMessage, setDisplayedMessage] = useState<string | null>(null);

  const { data: loans, isLoading: isLoanLoading } = useGetAllLoansByUserIdQuery(userId, { skip: !userId || !token });
  const { data: loanApplications, isLoading: isLoanApplicationsLoading } = useGetAllLoanApplicationsByUserIdQuery(
    userId as string,
    {
      skip: !userId || !token,
    }
  );
  const { data: repeatEligibility } = useGetRepeatEligibilityByUserIdQuery(
    { userId, baseDate: dayjs().format('YYYY-MM-DD') },
    { skip: !userId || !token }
  );

  const [activeLoanNumber, activeLoanAmount] = useMemo(() => {
    const activeLoans = loans?.filter((loanApplication) => loanApplication.loanInformation.loanState === 'Active');
    const activeLoanAmount = activeLoans?.reduce(
      (acc, loanApplication) => acc + loanApplication.loanInformation.loanAmountTotalWithFee,
      0
    );
    return [activeLoans?.length, activeLoanAmount];
  }, [loans]);

  const [isEligibleForQuote, isEligibleForUnutilizedLimit, isEligibleForRepeat] = useMemo(() => {
    if (!loanApplications) {
      return [null, null, null];
    }
    if (
      loanApplications?.every(
        (loanApplication) => loanApplication.status !== LoanApplicationStatusEnum.application_successful
      )
    ) {
      return [true, false, false];
    }
    const unutilisedLimitEligibility = loanApplications?.find(
      (loanApplication) => loanApplication.unutilisedLimitEligibility.isEligible
    )?.unutilisedLimitEligibility;
    if (unutilisedLimitEligibility) {
      setDisplayedMessage(unutilisedLimitEligibility.adminPortalMessage);
      return [false, true, false];
    }
    if (repeatEligibility?.isEligible) {
      return [false, false, true];
    }
    return [false, false, false];
  }, [loanApplications, repeatEligibility]);

  const renderEligibleResult = () => {
    const displayContent = {
      icon: 'check-circle',
      title: null,
      className: 'text-success',
      adminPortalMessage: null,
    };
    if (isEligibleForQuote) {
      displayContent.title = 'Eligible for quote';
    } else if (isEligibleForUnutilizedLimit) {
      displayContent.title = 'Unutilised limit available';
      displayContent.adminPortalMessage = displayedMessage && boldContent(displayedMessage);
    } else if (isEligibleForRepeat) {
      displayContent.title = 'Eligible for a repeat quote';
    } else {
      displayContent.icon = 'error';
      displayContent.title = 'Ineligible for a repeat quote';
      displayContent.className = 'text-grey-4';
      displayContent.adminPortalMessage =
        repeatEligibility?.adminPortalMessage && boldContent(repeatEligibility?.adminPortalMessage);
      if (
        isReturningCustomer(repeatEligibility?.input?.activeLoanCount, repeatEligibility?.input?.successfulLoanCount)
      ) {
        displayContent.title = 'Ineligible for a quote';
        displayContent.adminPortalMessage = 'Returning customers are not eligible for new quotes';
      }
    }
    return (
      <div>
        <div className="flex flex-row items-center">
          <Icon icon={`material-symbols:${displayContent.icon}`} className={displayContent.className} width={24} />
          <p className="font-medium text-lg ml-2">{displayContent.title}</p>
        </div>
        {displayContent.adminPortalMessage && (
          <p
            className="text-sm text-grey-5"
            dangerouslySetInnerHTML={{ __html: displayContent.adminPortalMessage }}
          ></p>
        )}
      </div>
    );
  };

  if (isLoanApplicationsLoading || isLoanLoading) {
    return <Spinner />;
  }

  return (
    <Card className="flex flex-row justify-start">
      <div className="pr-4">
        <Label>Active loans</Label>
        <p className="font-medium text-lg">{activeLoanNumber}</p>
      </div>
      <div className="px-4 border-l border-grey-2">
        <Label>Total active loan amount</Label>
        <p className="font-medium text-lg">{formatCurrency(activeLoanAmount)}</p>
      </div>
      <div className="pl-4 border-l border-grey-2">
        <Label>Quote</Label>
        {renderEligibleResult()}
      </div>
    </Card>
  );
};
