import Image from 'next/image';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FORM_KEY, IncomeDto, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { FinancialConfirmationSectionEnum, useFinancialConfirm, useFriendlyURL } from '@harmoney/hooks';
import {
  useGetFinancialSummaryQuery,
  useGetNetworthSourcesQuery,
  useGetUserProfileQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import {
  Alert,
  AmountFrequency,
  ArrowCircleRightIcon,
  Button,
  Card,
  CollapsibleHeader,
  Divider,
  Form,
  Label,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import {
  capitalizeTitle,
  frequencyOptionsWithYear,
  isPartnered,
  scrollToNearest,
  stringToBool,
  toggleYesNoOptions,
} from '@harmoney/ui-utils';
import { useScrollIntoView } from '@mantine/hooks';
import { IncomeAndExpenseEmploymenttypeEnum, IncomeAndExpenseFrequencyEnum, IncomePayTypeEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { isEmpty, kebabCase, some } from 'lodash';

import { CommonProps, ExtraAdminProps } from '../../common-props';

import { createRootSchema, getDefaultValues } from './form-config';
import { IncomeItem } from './IncomeItem';
import { correctOrderOfIncomeTypes, getIncomeDetailsByNetWorthSourceId, networthSourceOrderMap } from './utils';

const INCOME_NO_ID = NetworthSourceEnum.INCOME_NO_ID;

export function UpdateIncome({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps & ExtraAdminProps) {
  useFriendlyURL(taskFriendlyURL);

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ offset: 150 });
  const [elementId, setElementId] = useState(null);
  const pageRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState({ update: false, cancel: false });
  const { confirmSection } = useFinancialConfirm();
  const [formTouched, setFormTouched] = useState(false);
  const [otherIncomeOpen, setOtherIncomeOpen] = useState(false);
  const [incomeDisplayOrder, setIncomeDisplayOrder] = useState<number[]>([]);

  const { data: incomeTypes } = useGetNetworthSourcesQuery();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: userProfile } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const { data: financialSummary } = useGetFinancialSummaryQuery(
    { id: variables?.financialProfileId?.toString(), loanApplicationId: variables?.loanApplicationId?.toString() },
    {
      skip: !variables?.loanApplicationId?.toString() || !variables?.financialProfileId?.toString(),
      refetchOnMountOrArgChange: true,
    }
  );

  const prefilledData = useMemo(() => {
    if (financialSummary?.incomes) {
      return financialSummary.incomes;
    }
    return [];
  }, [financialSummary]);
  const shouldDisplayPartnerIncomeSection = useMemo(() => {
    const hasPartner = isPartnered(userProfile?.relationshipStatus);
    return hasPartner;
  }, [userProfile]);

  const form = useForm({
    mode: 'onTouched',
    schema: createRootSchema(shouldDisplayPartnerIncomeSection),
    defaultValues: { incomes: {} },
  });

  const { watch, register, setValue, getValues, resetField } = form;
  const watchForm = watch();
  const watchIncomes = watchForm.incomes;

  const disableNoIncomeOption = useMemo(() => {
    return Object.entries(watchIncomes).flat().length > 0 || watchIncomes[INCOME_NO_ID]?.length > 0;
  }, [Object.entries(watchIncomes), watchIncomes[INCOME_NO_ID]]);

  const handleAddIncome = (networthSourceId: NetworthSourceEnum) => {
    scrollToNearest(pageRef, 'center');
    setOtherIncomeOpen(false);
    const incomeItems = getValues(`incomes.${networthSourceId}`);
    const newIncomeItem = getDefaultValues(networthSourceId);
    if (incomeItems && incomeItems.length > 0) {
      setValue(`incomes.${networthSourceId}`, [...incomeItems, newIncomeItem]);
    } else {
      setValue(`incomes.${networthSourceId}`, [newIncomeItem]);
    }
    setIncomeDisplayOrder([...incomeDisplayOrder, networthSourceId]);
    setElementId(newIncomeItem.elementId);
  };

  const handleRemoveIncome = (networthSourceId: NetworthSourceEnum, index: number) => {
    const incomeItems = getValues(`incomes.${networthSourceId}`);
    const updatedIncomeItems = incomeItems.filter((_, i) => i !== index);
    if (isEmpty(updatedIncomeItems)) {
      const incomes = getValues('incomes');
      delete incomes[networthSourceId];
      setValue('incomes', incomes);
    } else {
      resetField(`incomes.${networthSourceId}`);
      setValue(`incomes.${networthSourceId}`, updatedIncomeItems);
    }
    setIncomeDisplayOrder(incomeDisplayOrder.filter((id) => id !== networthSourceId));
  };

  const handleCancel = async () => {
    try {
      setIsSubmitting({ update: false, cancel: true });
      await completeTaskWithData({ taskId });
    } catch (error) {
      console.error(`Failed to complete task [taskId: ${taskId}], error: ${error}`);
      setIsSubmitting({ update: false, cancel: false });
    }
  };

  const handleSubmit = async (data) => {
    try {
      const hasIncome = some(data.incomes, (item) => item.length > 0);

      if (!hasIncome) {
        setIsSubmitting({ update: false, cancel: false });
        setFormTouched(true);
        return;
      }

      const getDate = (month: string, year: string) => {
        const firstDateOfMonth = dayjs(`${+year}-${+month}-01`).toDate();
        return firstDateOfMonth;
      };

      let hasSelfEmployedIncome: boolean;
      let transformedData: IncomeDto[] = [];

      if (hasIncome) {
        hasSelfEmployedIncome = Object.entries(data.incomes).some(
          ([networthSourceId, incomes]) =>
            Number(networthSourceId) === NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID && (incomes as any[]).length > 0
        );

        transformedData = Object.entries(data.incomes).flatMap(([networthSourceId, incomes]) => {
          const sourceId = Number(networthSourceId) as NetworthSourceEnum;

          return (incomes as any[]).map((income): IncomeDto => {
            const baseIncome: IncomeDto = {
              id: income.id,
              networthSourceId: sourceId,
              declaredAmount: income.declaredAmount,
              frequency: income.frequency as IncomeAndExpenseFrequencyEnum,
            };

            if (sourceId === NetworthSourceEnum.INCOME_SALARY_WAGES_ID) {
              return {
                ...baseIncome,
                employmentCode: income.employmentCode,
                employmentType: income.employmentType as IncomeAndExpenseEmploymenttypeEnum,
                employmentStartDate: getDate(income.startEmploymentMonth, income.startEmploymentYear),
                seasonalWorkingMonths: income.seasonalWorkingMonths || '',
              };
            }

            if (sourceId === NetworthSourceEnum.INCOME_BENEFIT_ID) {
              return {
                ...baseIncome,
                benefitType: income.benefitType,
                benefitName: income.benefitName || '',
              };
            }

            if (sourceId === NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID) {
              return {
                ...baseIncome,
                selfEmploymentType: income.selfEmploymentType,
              };
            }

            if (sourceId === NetworthSourceEnum.INCOME_OTHER_ID) {
              return {
                ...baseIncome,
                otherIncomeType: income.otherIncomeType,
              };
            }

            return baseIncome;
          });
        });
      }

      if (shouldDisplayPartnerIncomeSection) {
        const hasPartnerIncome = stringToBool(data.hasPartnerIncome);
        if (!hasPartnerIncome) {
          transformedData.push({
            id: prefilledData.find((item) => item.networthSourceId === NetworthSourceEnum.INCOME_PARTNER_ID)?.id,
            networthSourceId: NetworthSourceEnum.INCOME_PARTNER_ID,
            declaredAmount: null,
            frequency: null,
            hasPartnerIncome: hasPartnerIncome,
            payType: null,
          });
        } else {
          const frequency =
            data.partnerIncomeAmount && data.partnerIncomeAmount > 0 ? data.partnerIncomeFrequency : null;
          transformedData.push({
            id: prefilledData.find((item) => item.networthSourceId === NetworthSourceEnum.INCOME_PARTNER_ID)?.id,
            networthSourceId: NetworthSourceEnum.INCOME_PARTNER_ID,
            declaredAmount: data.partnerIncomeAmount,
            frequency: frequency,
            hasPartnerIncome: hasPartnerIncome,
            payType: data.partnerIncomePayType,
          });
        }
      }

      // find the ones that exist in prefilledData but does not exist in transformedData and set needDelete to true
      const deleteData = prefilledData
        .filter((item) => !transformedData.find((newItem) => newItem.id === item.id))
        .map((item) => ({ id: item.id, needDelete: true }) as IncomeDto);
      transformedData.push(...deleteData);

      setIsSubmitting({ update: true, cancel: false });
      await completeTaskWithData({
        taskId,
        variables: { hasSelfEmployedIncome },
        formKey: FORM_KEY.INCOME_UPDATE,
        formData: { incomes: transformedData },
      });
      confirmSection(FinancialConfirmationSectionEnum.Income);
    } catch (error) {
      console.error(error);
      setIsSubmitting({ update: false, cancel: false });
    }
  };

  useEffect(() => {
    if (prefilledData) {
      const incomes = {};
      const order = [...incomeDisplayOrder];
      prefilledData.forEach((item) => {
        const income = { ...item };
        if (income.networthSourceId === INCOME_NO_ID) {
          incomes[INCOME_NO_ID] = [income];
        } else if (income.networthSourceId === NetworthSourceEnum.INCOME_PARTNER_ID) {
          setValue('hasPartnerIncome', income.hasPartnerIncome ? 'Yes' : 'No');
          setValue('partnerIncomeAmount', income.declaredAmount);
          setValue('partnerIncomeFrequency', income.frequency);
          setValue('partnerIncomePayType', income.payType as IncomePayTypeEnum);
        } else {
          if (income.networthSourceId === NetworthSourceEnum.INCOME_SALARY_WAGES_ID) {
            const employmentStartDate = dayjs(income.employmentStartDate);
            income['startEmploymentYear'] = employmentStartDate.format('YYYY');
            income['startEmploymentMonth'] = employmentStartDate.format('MM');
          }
          if (incomes[income.networthSourceId]) {
            incomes[income.networthSourceId].push(income);
          } else {
            incomes[income.networthSourceId] = [income];
          }
        }
        // set the display order if it is not in the list
        if (!order.includes(income.networthSourceId)) {
          order.push(income.networthSourceId);
        }
      });
      setIncomeDisplayOrder(order);
      setValue('incomes', incomes);
    }
  }, [financialSummary, setValue, prefilledData]);

  useEffect(() => {
    if (targetRef) {
      scrollIntoView();
    }
  }, [scrollIntoView, targetRef, elementId]);

  const renderPartnerIncome = () => {
    return (
      <Card>
        <ToggleGroup
          {...register('hasPartnerIncome')}
          key={`hasPartnerIncome`}
          label="Does your partner earn an income?"
          options={toggleYesNoOptions}
        />
        <Alert variant="light-info" className="mt-2 mb-0">
          Your partner&rsquo;s income helps us understand your household{' '}
          <strong>without impacting their credit score or making them a co-borrower.</strong>
        </Alert>
        {watchForm.hasPartnerIncome === 'Yes' && (
          <div className="flex flex-col gap-4 mt-4">
            <AmountFrequency
              register={register}
              label="What is their income?"
              inputKey="partnerIncomeAmount"
              selectKey="partnerIncomeFrequency"
              name="partner-income"
              options={frequencyOptionsWithYear}
            />
            <ToggleGroup
              {...register('partnerIncomePayType')}
              key="partnerIncomePayType"
              label={
                <span>
                  Is that their income <span className="font-medium">before</span> or{' '}
                  <span className="font-medium">after tax and deductions?</span>
                </span>
              }
              options={[
                { label: 'Before Tax', value: IncomePayTypeEnum.GROSS },
                { label: 'After Tax', value: IncomePayTypeEnum.NET },
              ]}
            />
          </div>
        )}
      </Card>
    );
  };

  return (
    <div>
      <h1>
        What kind of <span className="text-primary">income</span> do you have?
      </h1>

      <Form form={form} onSubmit={handleSubmit}>
        {incomeDisplayOrder.map((networthSourceId) => {
          const incomes = watchIncomes[networthSourceId];
          if (!incomeTypes || isEmpty(incomes)) return null;
          return (
            <CollapsibleHeader
              key={networthSourceId}
              open={true}
              title={getIncomeDetailsByNetWorthSourceId(incomeTypes, Number(networthSourceId)).name}
              subtitle={getIncomeDetailsByNetWorthSourceId(incomeTypes, Number(networthSourceId)).description}
              code={getIncomeDetailsByNetWorthSourceId(incomeTypes, Number(networthSourceId)).code}
            >
              {(incomes as IncomeDto[]).map((income, index) => (
                <div
                  key={`income-${networthSourceId}-${index}`}
                  ref={income['elementId'] === elementId ? targetRef : null}
                  className="mx-4 mb-4"
                >
                  <IncomeItem
                    networthSourceId={Number(networthSourceId)}
                    incomes={incomes}
                    index={index}
                    onRemoveItem={handleRemoveIncome}
                  />
                  {(incomes as IncomeDto[]).length - 1 === index && Number(networthSourceId) !== INCOME_NO_ID && (
                    <div className="text-center my-4">
                      <Button
                        onClick={() => handleAddIncome(Number(networthSourceId))}
                        size="medium"
                        variant="outline-secondary"
                      >
                        + Another{' '}
                        {Number(networthSourceId) === NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID
                          ? 'self employment'
                          : getIncomeDetailsByNetWorthSourceId(incomeTypes, Number(networthSourceId)).name}
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </CollapsibleHeader>
          );
        })}

        {shouldDisplayPartnerIncomeSection && renderPartnerIncome()}

        <CollapsibleHeader
          chevron
          title="Do you have any other incomes?"
          valid={formTouched}
          open={otherIncomeOpen}
          onCollapseChange={() => {
            setOtherIncomeOpen(!otherIncomeOpen);
            setFormTouched(false);
          }}
          disabled={watchIncomes[INCOME_NO_ID]?.length > 0}
        >
          {incomeTypes?.length > 0 &&
            correctOrderOfIncomeTypes(incomeTypes, networthSourceOrderMap)?.map((networthSource, i, arr) => (
              <div key={networthSource.id}>
                <button
                  type="button"
                  onClick={() => handleAddIncome(networthSource.id)}
                  className={`hover:bg-grey-1 flex w-full items-center justify-between space-x-4 p-4 ${
                    networthSource.id === INCOME_NO_ID && disableNoIncomeOption
                      ? 'bg-grey-1 hover:bg-grey-1 cursor-not-allowed'
                      : 'bg-white  cursor-pointer '
                  }`}
                  disabled={networthSource.id === INCOME_NO_ID && disableNoIncomeOption}
                >
                  <div
                    key={`income-${networthSource.id}`}
                    className={`flex cursor-pointer ${networthSource.id === INCOME_NO_ID && disableNoIncomeOption ? 'cursor-not-allowed' : ''}`}
                  >
                    <div className="flex flex-col">
                      <div className="flex items-center space-x-2">
                        <Image
                          src={`/assets/images/${kebabCase(networthSource.code)}.svg`}
                          className="grayscale"
                          alt={`${networthSource.code}_image`}
                          width={18}
                          height={18}
                        />

                        <Label>{capitalizeTitle(networthSource.name)}</Label>
                      </div>
                      <small className="text-grey-4 text-sm ml-6">{networthSource.description}</small>
                    </div>
                    <Divider className="text-grey-2 m-0 p-0" />
                  </div>
                </button>
                {i !== arr.length - 1 && <Divider className="text-grey-2 my-0" />}
              </div>
            ))}
        </CollapsibleHeader>

        {formTouched && <p className="text-error">Please select an option</p>}

        <div className="flex flex-col items-center">
          <Button
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            type="submit"
            className="mb-4"
            hasShadow
            isLoading={isSubmitting.update}
            disabled={isSubmitting.cancel}
          >
            Save
          </Button>
          <Button
            variant="tertiary"
            onClick={handleCancel}
            isLoading={isSubmitting.cancel}
            disabled={isSubmitting.update}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}
