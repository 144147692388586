import { useEffect, useMemo, useState } from 'react';
import { Frequency } from '@harmoney/api-interfaces';
import { useFormatQuoteOptionsV2, useFriendlyURL, useQuoteData } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetLoanApplicationQuery,
  useGetQuoteOptionsQuery,
  useGetUserQuery,
  useGetVariablesQuery,
  useSubmitLoanApplicationMutation,
} from '@harmoney/redux';
import { eventAnalytics, LOAN_APPLICATION_SUBMITTED } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, Divider, Form, Spinner, useForm } from '@harmoney/ui-design-system';
import { useLocalStorage, useScrollIntoView } from '@mantine/hooks';
import { LoanApplicationStatusEnum, RepaymentFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { CommonProps } from '../../common-props';
import {
  calculateEstablishmentFee,
  InterestSavings,
  QuoteIntroductionV2,
  QuoteReviewModal,
  QuoteSummaryV2,
  RepaymentCalculator,
} from '../shared';
import { createFormSchema, defaultValues } from '../ValidQuoteV3/form-config';

dayjs.extend(relativeTime);

export function ValidQuoteV4({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const userId = useAppSelector((state) => state.userId.value);
  const [_, setQuoteDetails] = useLocalStorage({
    key: 'quote-details',
    defaultValue: {},
  });
  const [uiState, setUIState] = useState({
    isSubmitting: false,
    isNotReadyClicking: false,
  });
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ offset: 150 });
  const [isQuoteReviewModalOpen, setIsQuoteReviewModalOpen] = useState(false);
  const [borrowingLimit, setBorrowingLimit] = useState<{ lowerLimit: number; upperLimit: number }>({
    lowerLimit: 0,
    upperLimit: 0,
  });

  const formSchema = createFormSchema(borrowingLimit.lowerLimit, borrowingLimit.upperLimit);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });
  const { watch, trigger } = form;
  const watchFields = watch();

  const { data: userData } = useGetUserQuery();
  const { data: variables } = useGetVariablesQuery(taskId);
  const { quoteData } = useQuoteData(taskId);
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });
  const [submitLoanApplication] = useSubmitLoanApplicationMutation();

  const { totalAcceptedAmount, establishmentFee } = useMemo(() => {
    const totalAccepted = watchFields.amount;
    const fee = calculateEstablishmentFee(totalAccepted, quoteData);
    return { totalAcceptedAmount: totalAccepted, establishmentFee: fee };
  }, [watchFields.amount, quoteData]);

  const { data: quoteOptionsData } = useGetQuoteOptionsQuery(
    {
      loanApplicationId: variables?.loanApplicationId.toString(),
      loanAmount: totalAcceptedAmount,
      establishmentFee,
    },
    {
      skip: !variables || !totalAcceptedAmount || !establishmentFee,
      refetchOnMountOrArgChange: true,
    }
  );

  const formattedQuoteOptions = useFormatQuoteOptionsV2(
    watchFields.repaymentFrequency as RepaymentFrequencyEnum,
    quoteOptionsData
  );

  const repaymentDetails = useMemo(() => {
    if (!quoteOptionsData || !watchFields.termInMonths || !watchFields.repaymentFrequency) return null;

    const transformedQuoteOptions = quoteOptionsData.reduce((repaymentDetails, option) => {
      const termInMonths = option.termInMonths;
      const repaymentFrequency = option.repaymentFrequency;

      return {
        ...repaymentDetails,
        [termInMonths]: {
          ...repaymentDetails[termInMonths],
          [repaymentFrequency]: {
            ...option,
          },
        },
      };
    }, {});

    return transformedQuoteOptions[watchFields.termInMonths][watchFields.repaymentFrequency];
  }, [quoteOptionsData, watchFields.repaymentFrequency, watchFields.termInMonths]);

  const { primaryPurpose, interestRate, quotePresentedAmount } = useMemo(() => {
    return {
      primaryPurpose: loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName,
      interestRate: loanApplicationData?.finalInterestRate as unknown as number,
      quotePresentedAmount: loanApplicationData?.quotePresentedAmount,
    };
  }, [
    loanApplicationData?.finalInterestRate,
    loanApplicationData?.loanApplicationPurposes,
    loanApplicationData?.quotePresentedAmount,
  ]);

  const isSameLoanLimitAcrossOptions = useMemo(() => {
    return quoteOptionsData?.every(({ loanLimitFromUmi }) => loanLimitFromUmi === quoteOptionsData[0].loanLimitFromUmi);
  }, [quoteOptionsData]);

  useEffect(() => {
    if (!quoteData || !loanApplicationData) return;

    form.setValue('amount', +quoteData.maximumBorrowingLimit);
    form.setValue('termInMonths', +quoteData.termInMonths);
    setBorrowingLimit({
      lowerLimit: quoteData.minimumBorrowingLimit,
      upperLimit: loanApplicationData.quotePresentedAmount,
    });
  }, [form, quoteData, loanApplicationData]);

  const validateForm = async () => {
    const isValid = await trigger();

    if (!isValid) {
      scrollIntoView();
    }

    return isValid;
  };

  const handleQuoteReviewClick = async () => {
    await validateForm();
    const isValid = await validateForm();
    if (!isValid) return;
    setIsQuoteReviewModalOpen(true);
  };

  const handleNotReadyClick = async () => {
    setUIState((prevState) => ({
      ...prevState,
      isNotReadyClicking: true,
    }));

    const isValid = await validateForm();
    if (!isValid) return;

    setQuoteDetails({
      ...repaymentDetails,
      primaryPurpose: primaryPurpose,
    });

    await completeTask({ taskId });

    setUIState((prevState) => ({
      ...prevState,
      isNotReadyClicking: false,
    }));
  };

  const handleSubmit = async () => {
    setUIState((prevState) => ({
      ...prevState,
      isSubmitting: true,
    }));

    const isValid = await validateForm();
    if (!isValid) return;

    await submitLoanApplication({
      id: variables.loanApplicationId.toString(),
      termInMonths: +form.getValues('termInMonths'),
      fundedAmount: +totalAcceptedAmount,
      repaymentFrequency: form.getValues('repaymentFrequency') as unknown as Frequency,
      status: LoanApplicationStatusEnum.application_in_progress,
    });

    eventAnalytics.track(LOAN_APPLICATION_SUBMITTED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    await completeTask({ taskId });
    setUIState((prevState) => ({
      ...prevState,
      isSubmitting: false,
    }));
  };

  if (!quoteData) return <Spinner />;

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="absolute -top-8 md:-top-[80px] w-full md:w-[756px] h-[200px] md:h-[245px] rounded-b-pill md:rounded-b-full bg-gradient-to-b from-primary-darker-1 to-primary" />

      <div className="z-10 w-full">
        <QuoteIntroductionV2
          preferredName={userData?.preferredName}
          quotePresentedAmount={quotePresentedAmount}
          interestRate={interestRate}
        />
      </div>

      <Card className="py-6 px-0">
        <Form form={form} onSubmit={handleSubmit}>
          <div className="px-4 pb-6">
            <RepaymentCalculator
              targetRef={targetRef}
              formattedQuoteOptions={formattedQuoteOptions}
              isSameLoanLimitAcrossOptions={isSameLoanLimitAcrossOptions}
            />
          </div>

          <Divider className="text-grey-2" />

          <div className="p-4">
            <QuoteSummaryV2 primaryPurpose={primaryPurpose} repaymentDetails={repaymentDetails} />
          </div>

          <div className="text-center px-4 pt-2">
            <Button
              onClick={handleQuoteReviewClick}
              variant="primary"
              alignIcon="end"
              icon={<ArrowCircleRightIcon size="large" />}
              isLoading={uiState.isSubmitting}
              className="mb-6"
            >
              Review your quote
            </Button>
            <Button onClick={handleNotReadyClick} variant="outline-secondary" disabled={uiState.isSubmitting}>
              Not ready yet?
            </Button>
          </div>
        </Form>
      </Card>

      <QuoteReviewModal
        primaryPurpose={primaryPurpose}
        repaymentDetails={repaymentDetails}
        isQuoteReviewModalOpen={isQuoteReviewModalOpen}
        isSubmitting={uiState.isSubmitting}
        onQuoteReviewModelOpen={setIsQuoteReviewModalOpen}
        onSubmit={handleSubmit}
      />

      <div className="mt-12 mb-8">
        <InterestSavings />
      </div>
    </div>
  );
}
