import { AssetAndLiability, NetworthSource } from '@prisma/client';

export enum LiabilityProvider {
  Harmoney = 'Harmoney',
}

export function isHarmoneyDebt(debt: AssetAndLiability & { networthSource: NetworthSource }): boolean {
  return debt.provider?.startsWith(LiabilityProvider.Harmoney) && debt.networthSource.name === 'personal loan';
}

export function isNonHarmoneyDebt(debt: AssetAndLiability & { networthSource: NetworthSource }): boolean {
  return !isHarmoneyDebt(debt);
}

export const isHarmoneyStellare2DebtInCreditFile = (organisation: string, bureauAccountId: string) => {
  return organisation === 'HARMONEY AUSTRALIA PTY LTD' && bureauAccountId?.match(/^L\d{11}$/);
};
