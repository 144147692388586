import { useFormContext } from 'react-hook-form';
import { Checkbox, CurrencyWithFrequency } from '@harmoney/ui-design-system';
import { convertToMonthlyAmount, formatCurrency } from '@harmoney/utilities';
import { AssetAndLiability, NetworthSource } from '@prisma/client';
import { capitalize, toUpper } from 'lodash';

type Props = {
  debt: Partial<AssetAndLiability> & {
    networthSource: Partial<NetworthSource>;
  };
};

export const ChooseDebtItemToConsolidate = ({ debt }: Props) => {
  const {
    register,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  return (
    <div key={debt?.id} className="flex flex-col gap-2 p-4">
      <div className="flex flex-row justify-between items-center gap-4">
        <div className="flex flex-col gap-1">
          <span>
            {toUpper(debt?.provider)} {capitalize(debt?.networthSource?.name)}
          </span>
          <div className="text-grey-4 text-sm flex flex-row gap-2">
            <span>{debt?.interestRate.toString()}% p.a.</span>
            <span>|</span>
            <CurrencyWithFrequency
              className="text-sm text-grey-4"
              amount={convertToMonthlyAmount(debt.repaymentAmount, debt.frequency)}
              frequency="monthly"
            />
          </div>
        </div>
        <Checkbox
          alignLabel="left"
          label={formatCurrency(debt.outstandingBalance)}
          className="font-medium"
          key={debt.id}
          checked={watch().debtsSelection[debt.id]}
          {...register(`debtsSelection.${debt.id}`, {
            onChange: () => {
              if (errors.debtsSelection) {
                clearErrors('debtsSelection');
              }
            },
          })}
          displayValidationMessage={false}
        />
      </div>
    </div>
  );
};
