import Link from 'next/link';
import React from 'react';
import { OriginationType } from '@harmoney/api-interfaces';
import { ArrowCircleRightIcon, Button, Card } from '@harmoney/ui-design-system';

type Props = {
  ctaText: string;
  mainProcessData: { processId: string };
};

const RepeatEligibleCard = ({ mainProcessData }: Props) => {
  const subtitle = 'Borrow more';
  const description = 'Need more money? Get a quote now';
  const ctaText = 'Get a quote';

  return (
    <Card className="flex flex-col items-stretch md:px-16">
      <div className="flex flex-col gap-1 pb-4">
        <p className="m-0 text-center text-lg font-semibold">{subtitle}</p>
        <p className="text-center text-sm text-grey-4">{description}</p>
      </div>
      {mainProcessData?.processId && (
        <Link
          href={`journey?originationType=${OriginationType.REPEAT}&journeyId=${mainProcessData?.processId}`}
          passHref
          className="text-center"
        >
          <Button alignIcon="end" icon={<ArrowCircleRightIcon size="large" />} variant="primary">
            {ctaText}
          </Button>
        </Link>
      )}
    </Card>
  );
};

export default RepeatEligibleCard;
