import { LoanApplicationDto } from '@harmoney/api-interfaces';
import { Card, Divider, Label } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';
import { AssetAndLiability } from '@prisma/client';
import { capitalize, toUpper } from 'lodash';

type DebtsSummaryProps = {
  loanApplicationData: LoanApplicationDto;
  postApprovalDebtsData: (AssetAndLiability & { networthSourceName: string })[];
  withCard?: boolean;
};

export const DebtsSummaryV2 = ({ loanApplicationData, postApprovalDebtsData, withCard = false }: DebtsSummaryProps) => {
  const debtsData = (
    <div className="flex flex-col gap-2 text-sm">
      {postApprovalDebtsData?.map((debt) => (
        <div key={debt?.id} className="flex flex-row justify-between items-center mb-0 gap-2">
          <span>
            {toUpper(debt?.provider)} {''} {capitalize(debt?.networthSourceName)}
          </span>
          <span>{formatCurrency(debt?.outstandingBalance)}</span>
        </div>
      ))}
      <Divider className="mx-0 text-grey-2" />
      <p className="flex flex-row justify-between items-center gap-2 mb-0 text-sm">
        You&rsquo;re consolidating
        <span className="font-medium">{formatCurrency(loanApplicationData?.quoteMaxBorrowingLimit)}</span>
      </p>
    </div>
  );
  return (
    <>
      {withCard ? (
        <Card className="!p-0">
          <div className="flex flex-col ">
            <Label className="font-medium p-4">Debts to consolidate</Label>
            <Divider className="m-0 text-grey-2" />
            <span className="p-4"> {debtsData}</span>
          </div>
        </Card>
      ) : (
        <div className="flex flex-col ">
          <span className="mb-2">{debtsData} </span>
          <Divider className="mx-0 text-grey-2 mb-4" />
        </div>
      )}
    </>
  );
};
