import { Fragment, useEffect, useMemo, useState } from 'react';
import { DebtType } from '@harmoney/api-interfaces';
import { FinancialConfirmationSectionEnum } from '@harmoney/hooks';
import { Alert, Badge, Button, Card, CurrencyWithFrequency, Divider, Label } from '@harmoney/ui-design-system';
import { booleanToYesNo } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';

import { FinancialProfileProps } from '../financial-profile-props';

import { formatProviderName } from './util';

export const Debts = ({
  financialSummary,
  confirmedSections,
  onUpdate,
  creditFileLiabilities,
  onConfirmSection,
}: FinancialProfileProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const debts = financialSummary?.liabilities;
  const [sortedDebt, setSortedDebt] = useState(debts);

  const handleUpdateInfo = async () => {
    try {
      setIsSubmitting(true);
      await onUpdate(FinancialConfirmationSectionEnum.Debts);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  const displayUpToDate = useMemo(() => {
    return (
      confirmedSections?.includes(FinancialConfirmationSectionEnum.Debts) &&
      confirmedSections[confirmedSections.length - 1] === FinancialConfirmationSectionEnum.Debts
    );
  }, [confirmedSections?.length]);

  useEffect(() => {
    const sortedDebt =
      debts &&
      debts?.length > 0 &&
      [...debts].sort((a, b) => {
        // Sort by paidOff status, with unpaid debts coming first
        if (a.paidOff === false && b.paidOff !== false) {
          return -1;
        }
        if (a.paidOff !== false && b.paidOff === false) {
          return 1;
        }
        if (a.paidOff === null && b.paidOff !== null) {
          return -1;
        }
        if (a.paidOff !== null && b.paidOff === null) {
          return 1;
        }

        // If paidOff status is the same, sort by recordOrigin
        if (a.recordOrigin === b.recordOrigin) {
          return 0;
        }

        // Sort by recordOrigin, with "prefill" coming before "manual"
        return a.recordOrigin === 'prefill' ? -1 : 1;
      });
    setSortedDebt(sortedDebt);
  }, [debts]);

  const renderDebtsBeforeConfirmation = () => {
    return (
      <div className="flex flex-col items-center px-4 py-4 ">
        <p>
          Just give us a quick <span className="font-medium">update</span> on your current debt situation
        </p>
      </div>
    );
  };

  const renderButtons = () => {
    if (confirmedSections.includes(FinancialConfirmationSectionEnum.Debts)) {
      return (
        <div className="flex flex-col gap-4 px-2 py-6 items-center">
          {displayUpToDate && (
            <Alert variant="success" className="mb-0 sm:max-w-[20.5rem] w-full" autoDismissInMilliseconds={1500}>
              <p className="text-sm">Up to date</p>
            </Alert>
          )}
          <Button
            type="button"
            variant="outline-secondary"
            hasShadow
            isLoading={isSubmitting}
            onClick={handleUpdateInfo}
          >
            Update my info
          </Button>
        </div>
      );
    }
    return (
      <div className="flex flex-col gap-6 px-2 pb-6 items-center">
        <Button type="button" variant="primary" isLoading={isSubmitting} hasShadow onClick={handleUpdateInfo}>
          Update
        </Button>
      </div>
    );
  };

  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Debts</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />
      <div>
        {confirmedSections.includes(FinancialConfirmationSectionEnum.Debts)
          ? sortedDebt?.map((debt, index) => (
              <Fragment key={`liability-${index}`}>
                <div className="py-4 px-2">
                  <div className="flex flex-row items-center px-2 ">
                    <div className="basis-2/3 font-medium">
                      {formatProviderName(debt.provider, debt.otherProvider, debt.networthSourceName)}
                    </div>
                    <div className="flex basis-1/3 justify-end">
                      {debt.recordOrigin === 'prefill' && (
                        <Badge className="text-xs text-grey-4 mb-1" label="From credit file" variant="tertiary" />
                      )}
                    </div>
                  </div>
                  {debt.paidOff && (
                    <div className="flex flex-col px-2 text-sm pt-4">
                      <div className="flex flex-row items-center">
                        <div className="basis-2/3">Status</div>
                        <div className="flex basis-1/3 justify-end font-medium">Paid off</div>
                      </div>
                    </div>
                  )}
                  {!debt.paidOff && (
                    <div className="flex flex-col px-2 text-sm pt-4">
                      {debt.networthSourceName === DebtType.MORTGAGE && (
                        <div className="flex flex-row items-center">
                          <div className="basis-2/3">Shared mortgage</div>
                          <div className="flex basis-1/3 justify-end font-medium">
                            {booleanToYesNo(debt.isMortgageShared)}
                          </div>
                        </div>
                      )}
                      {debt.repaymentAmount && (
                        <div className="flex flex-row items-center">
                          <div className="basis-2/3">Repayment</div>
                          <div className="flex basis-1/3 justify-end font-medium">
                            <CurrencyWithFrequency frequency={debt.frequency} amount={debt.repaymentAmount} />
                          </div>
                        </div>
                      )}
                      {debt.creditLimit && debt.networthSourceName !== DebtType.BUY_NOW_PAY_LATER && (
                        <div className="flex flex-row items-center">
                          <div className="basis-2/3">Credit limit</div>
                          <div className="flex basis-1/3 justify-end font-medium">
                            {formatCurrency(debt.creditLimit)}
                          </div>
                        </div>
                      )}
                      {debt.outstandingBalance && (
                        <div className="flex flex-row items-center">
                          <div className="basis-2/3">Outstanding balance</div>
                          <div className="flex basis-1/3 justify-end font-medium">
                            {formatCurrency(debt.outstandingBalance)}
                          </div>
                        </div>
                      )}
                      {debt.paysOutstandingBalance && (
                        <div className="flex flex-row items-center">
                          <div className="basis-2/3">Outstanding balance paid every month</div>
                          <div className="flex basis-1/3 justify-end font-medium">{debt.paysOutstandingBalance}</div>
                        </div>
                      )}
                      {debt.interestRate >= 0 && (
                        <div className="flex flex-row items-center">
                          <div className="basis-2/3">Interest rate</div>
                          <div className="flex basis-1/3 justify-end font-medium">{debt.interestRate}% p.a.</div>
                        </div>
                      )}
                      {debt.isResidence === true &&
                        debt.isJointMortgage &&
                        debt.jointMortgageAmount &&
                        debt.jointMortgageFrequency && (
                          <div className="flex flex-row items-center">
                            <div className="basis-2/3">Contribution</div>
                            <div className="flex basis-1/3 justify-end font-medium">
                              <CurrencyWithFrequency
                                frequency={debt.jointMortgageFrequency}
                                amount={debt.jointMortgageAmount}
                              />
                            </div>
                          </div>
                        )}
                      {debt.isResidence === false && (
                        <div className="flex flex-row items-center">
                          <div className="basis-2/3">Rental income</div>
                          <div className="flex basis-1/3 justify-end font-medium">
                            {debt.rentalIncomeCoversMortgage ? 'Covers mortgage' : 'No rental income'}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <Divider className="text-grey-1 m-0 px-2" />
              </Fragment>
            ))
          : renderDebtsBeforeConfirmation()}
      </div>

      {renderButtons()}
    </Card>
  );
};
